$font-family-sans-serif: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

@import "~react-toastify/dist/ReactToastify.css";
@import "~react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "./vendor/scss/sb-admin-2.scss";

@media screen and (min-width: 768px) {
  ul:not(.toggled) {
    li.nav-item {
      .nav-link {
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
      }
    }
  }
}

ul.toggled,
body.sidebar-toggled {
  li.nav-item {
    .nav-link > svg {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  li.nav-item {
    .nav-link > svg {
      display: none;
    }
  }
}

.sidebar #sidebarToggle::after,
.sidebar.toggled #sidebarToggle::after {
  font-weight: inherit;
  content: inherit;
  font-family: inherit;
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: 0.2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: 0.2;
  }
}

.loading span {
  /**
   * Use the blink animation, which is defined above
   */
  animation-name: blink;
  /**
   * The animation should take 1.4 seconds
   */
  animation-duration: 1.4s;
  /**
   * It will repeat itself forever
   */
  animation-iteration-count: infinite;
  /**
   * This makes sure that the starting style (opacity: .2)
   * of the animation is applied before the animation starts.
   * Otherwise we would see a short flash or would have
   * to set the default styling of the dots to the same
   * as the animation. Same applies for the ending styles.
   */
  animation-fill-mode: both;

  &:nth-child(2) {
    /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
    animation-delay: 0.4s;
  }
}

.dashboard__img {
  max-width: 500px;
}

.search-results__card {
  position: absolute;
  z-index: 50;
  top: $topbar-base-height;
  left: 0;
  border-top: none;
  border-radius: 0;
}

.clickable {
  cursor: pointer;
}

table {
  &.kv {
    tr {
      td:first-child {
        background-color: $gray-100;
        width: 20%;
      }
    }

    &.kv--40 {
      tr {
        td:first-child {
          background-color: $gray-100;
          width: 40%;
        }
      }
    }
  }
}

// reduce the "boldness"...
.sidebar .sidebar-brand .sidebar-brand-text {
  font-weight: initial;
}
