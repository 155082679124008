// Background Gradient Utilities

@each $color, $value in $theme-colors {
  .bg-gradient-#{$color} {
    background-color: $value;
    background-image: linear-gradient(
      180deg,
      $value 10%,
      darken($value, 15%) 100%
    );
    background-size: cover;
  }
}

// Grayscale Background Utilities

@each $level, $value in $grays {
  .bg-gray-#{$level} {
    background-color: $value !important;
  }
}
