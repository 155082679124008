.react-autosuggest {
  &__container {
    position: relative;

    .react-autosuggest__suggestions-container {
      position: absolute;
      top: 100%;
      left: 0;
      display: none;
      z-index: 1000;
      float: left;
      min-width: 10rem;
      padding: 0.5rem 0;
      margin: 0.125rem 0;
      color: #858796;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e3e6f8;
      border-radius: 0.35rem;

      box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15) !important;
      font-size: 0.85rem;
    }

    &--open {
      .react-autosuggest__suggestions-container {
        display: block;
      }
    }
  }

  &__suggestions-list {
    padding: 0;
    list-style: none;
    margin-bottom: 0;
  }

  &__suggestion {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #3a3b45;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;

    cursor: pointer;

    &--highlighted {
      color: #2e2f37;
      text-decoration: none;
      background-color: #f8f9fc;
    }
  }
}
