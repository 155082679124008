@import "../../../vendor/scss/variables";

// TODO: media query to remove this for sm screens
.filter-col {
  border-right: 1px $gray-400 solid;
}

.filter__list {
  overflow-x: hidden;
}

.filter__section {
}

.filter__body {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.filter__header {
  border-bottom: medium $gray-200 solid;
}

.filter__option {
}
